<section class="main-sub-sec" fxLayout="column" fxLayoutAlign="center center">
  <div class="nav-dash">
    <button (click)="navClick('/about')" class="kave-btn">
      <span class="kave-line"></span>
      About
  </button>
  <button (click)="navClick('/projects')" class="kave-btn">
    <span class="kave-line"></span>
    Projects
  </button>
  <button (click)="navClick('/skills')" class="kave-btn">
    <span class="kave-line"></span>
    Skills
  </button>
  <button (click)="navClick('/contact')" class="kave-btn">
    <span class="kave-line"></span>
    Contact
  </button>
  <button (click)="navClick('/about')" class="kave-btn">
    <span class="kave-line"></span>
    Download CV
  </button>
  </div>

</section>