<section class="main-sub-sec">
  <div id="home" class="sub-sec" fxLayout="column" fxLayoutAlign="center center">
    <div class="title-sec" ngStyle.lt-md="'width':'95%'">
      <h3 ngStyle.lt-md="'font-size':'20px'">Hello,</h3>
      <h2 ngStyle.lt-md="'font-size':'25px'">I'm <span>Michiel</span> Waeben</h2>
      <h1 ngStyle.lt-md="'font-size':'30px'">I'm a <span id="job-title"
          data-text="Software Engineer., Web Developer., Coffee Addict., UI Designer., Mobile Developer., Team Player."></span>
      </h1>
      <div class="socials-sec" fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="20px">
        <a href="https://www.instagram.com/michielwbn/" target="_blank"><i class="fab fa-instagram social-link"></i></a>
        <a href="https://be.linkedin.com/in/michiel-waeben-74b9901a1" target="_blank"><i class="fab fa-linkedin social-link"></i></a>
        <a href="https://twitter.com/MWaeben" target="_blank"><i class="fab fa-twitter social-link"></i></a>
        <a href="https://www.facebook.com/michiel.waeben/" target="_blank"><i class="fab fa-facebook-square social-link"></i></a>
      </div>
    </div>
    <div class="background-sec" fxLayout="row" fxLayoutAlign="end center">
      <div ngStyle.lt-md="'width':'80%'" class="img-split-sec">
        <div class="img-container">
          <div class="img"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="about" class="sub-sec solid-bg-dark" ngStyle.lt-md="'height':'fit-content'" fxLayout="row"
    fxLayout.lt-md="column" fxLayoutAlign="center center">
    <div class="about-sec" fxLayout.lt-md="column" fxLayoutAlign="center center" ngStyle.lt-md="'padding':'40px'">
      <div class="main-img-container">
        <div class="corner-square-tl" #cornerTl enterTheViewportNotifier
          (visibilityChange)="visibilityChangeHandler($event, cornerTl)"></div>
        <div class="corner-square-br" #cornerBr enterTheViewportNotifier
          (visibilityChange)="visibilityChangeHandler($event, cornerBr)"></div>
        <div class="img-border" ngStyle.lt-md="'height':'440px'; 'width':'280px'" #imgBorder fxLayout.lt-md="column"
          enterTheViewportNotifier (visibilityChange)="visibilityChangeHandler($event, imgBorder)"
          fxLayoutAlign="center center">
          <div class="spinning-border"></div>
          <div class="about-img" ngStyle.lt-md="'height':'420px'; 'width':'260px'">

          </div>
        </div>
      </div>
    </div>
    <div class="about-sec" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="start center">
      <div class="about-text-sec" fxLayout="column" fxLayoutAlign="center center">
        <div class="text-overlapper" fxLayout.lt-md="column" fxLayoutAlign="center center">
          <h1 ngStyle.lt-md="'font-size':'66px'">ABOUT ME</h1>
          <h2>I'm Michiel</h2>
        </div>
        <p>I am a developer from Limburg, a small province in Belgium. I pretty much run on coffee! My
          hobbies are working out, mountainbiking, going out with friends and playing videogames. In my spare time I
          also like to try
          out
          new technologies.</p>
        <p>Through the years I've worked on multiple projects in languages like C#, Java, Python, Kotlin, JavaScript,
          TypeScript, etc. I also have experience with frameworks like
          Angular, Vue.js and React. I have a small preference to web-apps but I've also been working on a couple of
          mobile
          apps
          made in Android studio and Xamarin.</p>
        <p>Today I focus on providing custom-made and professional software solutions for general/industrial purposes.
          My main goal is delivering a polished product coupled to a positive user experience.</p>
        <!--<button
          onclick="window.open('https://firebasestorage.googleapis.com/v0/b/michielwbnwebsite.appspot.com/o/cv%2FCurriculum-Vitae-Michiel-Waeben.pdf?alt=media&token=146fb65d-69ff-4b49-aff8-fa7f9566d75d','_blank')">Download
          CV</button>-->
      </div>
    </div>
  </div>
  <div id="experience" class="sub-sec" style="height: fit-content" ngStyle.lt-md="'height':'fit-content'"
    fxLayout="column" fxLayoutAlign="center start">
    <div class="text-overlapper" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <h1 ngStyle.lt-md="'font-size':'66px'">EXPERIENCE</h1>
      <h2>Qualifications</h2>
    </div>
    <div style="width: 100%; height: 100%;" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start">
      <div class="timeline-sec" fxLayout.lt-md="column" fxLayoutAlign="center center">
        <div class="timeline">
          <div class="title" fxLayout="row">
            <i class="fas fa-laptop-code"></i>
            <h3>Work Experience</h3>
          </div>
          <div class="events">
            <div class="event" #event1 enterTheViewportNotifier
              (visibilityChange)="visibilityChangeHandler($event, event1)">
              <div class="knob"></div>
              <div class="event-card" ngStyle.lt-md="'width':'100%'; 'height':'fit-content">
                <div class="card-content" fxLayout="column">
                  <h2>2016</h2>
                  <div class="date">
                    <h1>Intern - Social Worker</h1>
                    <h3>CAD</h3>
                  </div>
                  <div class="description">
                    <p>Internship at CAD (Center for Alcohol and Other Drug related issues) located in Hasselt.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="event" #event2 enterTheViewportNotifier
              (visibilityChange)="visibilityChangeHandler($event, event2)">
              <div class="knob"></div>
              <div class="event-card" ngStyle.lt-md="'width':'100%'; 'height':'fit-content">
                <div class="card-content" fxLayout="column">
                  <h2>2021</h2>
                  <div class="date">
                    <h1>Intern - Software Developer</h1>
                    <h3>aariXa</h3>
                  </div>
                  <div class="description">
                    <p>Internship at aariXa focusing on development of an asset/fleet management tool.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="event" #event3 enterTheViewportNotifier
              (visibilityChange)="visibilityChangeHandler($event, event3)">
              <div class="knob"></div>
              <div class="event-card" ngStyle.lt-md="'width':'100%'; 'height':'fit-content">
                <div class="card-content" fxLayout="column">
                  <h2>2021 - today</h2>
                  <div class="date">
                    <h1>Software Engineer / Consultant</h1>
                    <h3>Exellys</h3>
                  </div>
                  <div class="description">
                    <p>Software Engineer and Consultant trained by Exellys and sent out to assist their clients with
                      developing and managing business/software solutions</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="event" #event4 enterTheViewportNotifier
              (visibilityChange)="visibilityChangeHandler($event, event4)">
              <div class="knob"></div>
              <div class="event-card" ngStyle.lt-md="'width':'100%'; 'height':'fit-content">
                <div class="card-content" fxLayout="column">
                  <h2>2021 - today</h2>
                  <div class="date">
                    <h1>Software Engineer</h1>
                    <h3>Melexis</h3>
                  </div>
                  <div class="description">
                    <p>Software Developer at Melexis, working on a production control system, maintaining production
                      sheets and managing internal project management software.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="timeline-sec" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="start center">
        <div class="timeline">
          <div class="title" fxLayout="row">
            <i class="fas fa-graduation-cap"></i>
            <h3>Education</h3>
          </div>
          <div class="events">
            <div class="event" #education1 enterTheViewportNotifier
              (visibilityChange)="visibilityChangeHandler($event, education1)">
              <div class="knob"></div>
              <div class="event-card" ngStyle.lt-md="'width':'100%'; 'height':'fit-content">
                <div class="card-content" fxLayout="column">
                  <h2>2011 - 2017</h2>
                  <div class="date">
                    <h1>Social And Technical Sciences</h1>
                    <h3>Sint-Augustinusinstituut Bree</h3>
                  </div>
                  <div class="description">
                    <p>In addition to a strong general education, this field of study
                      focuses on human, social and natural sciences.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="event" #education2 enterTheViewportNotifier
              (visibilityChange)="visibilityChangeHandler($event, education2)">
              <div class="knob"></div>
              <div class="event-card" ngStyle.lt-md="'width':'100%'; 'height':'fit-content">
                <div class="card-content" fxLayout="column">
                  <h2>2017 - 2021</h2>
                  <div class="date">
                    <h1>Applied Informatics</h1>
                    <h3>PXL University of Applied Sciences and Arts</h3>
                  </div>
                  <div class="description">
                    <p>Applied Informatics with a specialization in Software Development. Focusing on both Web and
                      Mobile development tackling both backend and frontend.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div id="skills" class="main-skill-sec solid-bg-dark" ngStyle.lt-md="'height':'fit-content'" fxLayout="column"
    fxLayoutAlign="center start">
    <div class="text-overlapper" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <h1 ngStyle.lt-md="'font-size':'66px'">SKILLS</h1>
      <h2>My skills</h2>
    </div>
    <div style="width: 100%; height: 100%; margin-bottom: 20px;" fxLayout="row" fxLayout.lt-md="column">
      <div class="skill-sec" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="start center">
        <div id="graph-1">
          <div *ngFor="let skill of leftSkillData" [id]="skill.title" class="progressBar">
            <label class="barLabel" [for]="skill.title">{{skill.title}}</label>
            <div class="progressTrack">
              <div #skillbar class="progressFill" [style.width.%]="skill.percent" enterTheViewportNotifier
                (visibilityChange)="widthChangeHandler($event, skillbar)">
                <div class="marker"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="skill-sec" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="start center">
        <div id="graph-2">
          <div *ngFor="let skill of rightSkillData" [id]="skill.title" class="progressBar">
            <label class="barLabel" [for]="skill.title">{{skill.title}}</label>
            <div class="progressTrack">
              <div #skillbar class="progressFill" [style.width.%]="skill.percent" enterTheViewportNotifier
                (visibilityChange)="widthChangeHandler($event, skillbar)">
                <div class="marker"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="projects" class="project-sec" ngStyle.lt-md="'height':'fit-content'" fxLayout="column"
    fxLayoutAlign="center start">
    <div class="text-overlapper" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <h1 ngStyle.lt-md="'font-size':'66px'">PORTFOLIO</h1>
      <h2>My projects</h2>
    </div>
    <div class="card-container">
      <div class="project-card" #projectCard enterTheViewportNotifier ngStyle.lt-md="'width':'300px'; 'height': '340px';"
        (visibilityChange)="visibilityChangeHandler($event, projectCard)" *ngFor="let project of shownProjects">
        <div class="img-sec">
          <h2>{{project.platform}}</h2>
          <video loop="true" autoplay="autoplay" disableRemotePlayback muted="muted"
            oncanplay="this.muted=true; play()">
            <source [src]="project.image" type="video/mp4">
          </video>
        </div>
        <div class="info-sec">
          <div class="description">
            <h3>{{project.title}}</h3>
            <p>{{project.description}}</p>
          </div>
          <hr>
          <div class="controls">
            <h4 *ngIf="project.completion != 100">{{project.completion}}% completed</h4>
            <h4 class="completed-label" *ngIf="project.completion == 100">Completed</h4>
            <!--<button>More Info</button>-->
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%" class="project-button-container" fxLayoutAlign="center center" fxLayout="row">
      <button *ngIf="amountOfVisibleProjects != maxAmountOfProjects" (click)="showMoreProjects()">Show more</button>
      <button *ngIf="amountOfVisibleProjects > 6" (click)="showLessProjects()">Show less</button>
    </div>
  </div>
  <div id="contact" class="contact-sec solid-bg-dark" ngStyle.lt-md="'height':'fit-content'" fxLayout="column"
    fxLayoutAlign="center center">
    <div class="text-overlapper" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <h1 ngStyle.lt-md="'font-size':'66px'">CONNECTION</h1>
      <h2>Contact me</h2>
    </div>
    <i class="fas fa-users"></i>
    <div class="social-media-info" ngStyle.lt-md="'border-radius':'0'; 'height': 'fit-content'; 'padding': '40px'">
      <div class="link-list" fxLayout.lt-md="column" ngStyle.lt-md="'gap':'40px'">
        <a href="https://www.instagram.com/michielwbn/" target="_blank"><i class="fab fa-instagram social-media-button"></i></a>
        <a href="https://be.linkedin.com/in/michiel-waeben-74b9901a1" target="_blank"><i class="fab fa-linkedin social-media-button"></i></a>
        <a href="https://twitter.com/MWaeben" target="_blank"><i class="fab fa-twitter social-media-button"></i></a>
        <a href="https://www.facebook.com/michiel.waeben/" target="_blank"><i class="fab fa-facebook-square social-media-button"></i></a>
      </div>
    </div>
    <div class="other-links"  fxLayout.lt-md="column">
      <div class="other-contact-link" fxLayout="row" fxLayoutAlign="center center">
        <i class="far fa-envelope"></i>
        <h2>m.waeben@gmail.com</h2>
      </div>

      <div class="other-contact-link" fxLayout="row" fxLayoutAlign="center center">
        <i class="fas fa-mobile-alt"></i>
        <h2>+32 471 21 54 21</h2>
      </div>
    </div>
  </div>
</section>