<section class="main-pad" ngClass.lt-md="mobile-pad">
        <section class="main-sec" ngClass.lt-md="mobile-main-sec">
                <div id="boot_screen" class="boot_screen" fxLayout="row" fxLayoutAlign="center center">
                        <button id="boot-btn" class="boot-btn" (click)="initiateBoot()">Access Portfolio</button>
                </div>
                <div *ngIf="showUi" id="main-screen" class="boot_screen boot-bg">
                        <div class="background-sec" fxLayout="row" fxLayoutAlign="start center">
                                <div class="lines-bg" fxLayout="row">
                                        <div class="line-1"></div>
                                        <div class="line-2"></div>
                                        <div class="sub-line-sec">
                                                <div class="line-3"></div>
                                        </div>
                                </div>
                                <h1 fxHide.lt-md class="background-text-l">P<span>O</span>RTFO<span
                                                style="animation-delay: 0.1s;">L</span>IO
                                </h1>
                        </div>
                        <section oncroll="onWindowScroll()" id="navBar" class="nav-bar" fxLayout="row">
                                <div class="split-sec-1">

                                </div>
                                <div class="split-sec-2">
                                        <div class="nav-sec" fxHide.lt-md fxLayout="row" fxLayoutAlign="start center"
                                                fxLayoutGap="50px">
                                                <a (click)="scrollToSection('#home')" class="nav-link">HOME</a>
                                                <a (click)="scrollToSection('#about')" class="nav-link">ABOUT</a>
                                                <a (click)="scrollToSection('#experience')" class="nav-link">EXPERIENCE</a>
                                                <a (click)="scrollToSection('#skills')" class="nav-link">SKILLS</a>
                                                <a (click)="scrollToSection('#projects')" class="nav-link">PROJECTS</a>
                                                <a (click)="scrollToSection('#contact')" class="nav-link">CONTACT</a>
                                        </div>
                                </div>
                        </section>
                        <section class="body">
                                <router-outlet></router-outlet>
                        </section>
                        <div fxHide.gt-sm class="menu-button-container" fxLayoutAlign="center center">
                                <a class="menu-4" [class.menu-active]="menuActive" id="nav-menu" (click)="toggleMenu()"
                                        data-menu="4"></a>
                        </div>
                        <div id="navScreen" class="nav-screen">
                                <svg id="hexagons" version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%"
                                        height="100%" viewBox="0 0 1920.000000 1080.000000"
                                        preserveAspectRatio="xMinYMin slice">

                                        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M0 10002 c0 -607 3 -801 12 -810 9 -9 140 -12 529 -12 l517 0 45 73
                c25 39 77 129 116 198 38 70 82 144 97 165 14 22 37 62 51 89 31 62 96 173
                138 240 18 28 42 69 54 92 l21 42 -83 143 c-174 296 -242 415 -287 495 l-45
                82 -582 1 -583 0 0 -798z" />
                                                <path d="M1253 10788 c2 -7 44 -81 93 -165 49 -84 136 -233 194 -333 l105
                -180 519 2 520 3 34 60 c19 33 65 112 102 175 37 63 92 158 121 210 29 52 67
                118 84 145 18 28 37 60 44 73 l12 22 -917 0 c-729 0 -915 -3 -911 -12z" />
                                                <path d="M3124 10733 c-20 -38 -49 -86 -64 -108 -14 -22 -57 -96 -95 -165 -38
                -69 -80 -143 -95 -165 -15 -22 -48 -80 -74 -129 l-48 -89 16 -34 c9 -19 50
                -90 90 -157 40 -66 92 -157 116 -201 24 -44 57 -102 75 -130 17 -27 67 -113
                110 -190 43 -77 85 -150 94 -162 l16 -22 520 0 520 0 29 47 c16 26 42 72 59
                102 17 30 54 96 82 145 29 50 72 124 96 165 23 41 90 155 147 254 l105 178
                -43 82 c-24 44 -53 95 -65 111 -13 17 -50 81 -84 143 -35 62 -86 152 -115 200
                -29 48 -65 111 -80 140 l-26 52 -624 0 -624 0 -38 -67z" />
                                                <path d="M4490 10795 c0 -2 24 -44 53 -92 30 -48 88 -149 129 -223 42 -74 94
                -164 115 -200 22 -36 53 -89 69 -117 l29 -53 520 0 520 0 29 53 c16 28 55 95
                86 147 31 52 74 127 95 165 21 39 71 125 112 192 40 68 73 125 73 128 0 3
                -412 5 -915 5 -503 0 -915 -2 -915 -5z" />
                                                <path d="M6374 10748 c-15 -29 -51 -92 -80 -140 -29 -48 -81 -138 -115 -200
                -34 -62 -74 -131 -89 -153 -15 -22 -44 -72 -65 -111 l-38 -72 85 -143 c47 -79
                100 -171 118 -204 19 -33 59 -103 90 -155 31 -52 86 -146 121 -207 35 -62 73
                -129 84 -148 l20 -35 521 0 520 0 33 53 c17 28 63 106 100 172 104 183 187
                326 277 480 120 204 114 178 58 281 -26 49 -59 107 -74 129 -15 22 -57 96 -95
                165 -38 69 -81 143 -95 165 -15 22 -44 70 -64 108 l-38 67 -624 0 -624 0 -26
                -52z" />
                                                <path d="M7741 10778 c7 -13 26 -45 44 -73 17 -27 55 -93 84 -145 29 -52 84
                -147 121 -210 37 -63 83 -142 102 -175 l34 -60 520 -3 519 -2 101 172 c55 95
                120 207 143 248 24 41 68 116 97 167 30 51 54 94 54 97 0 4 -412 6 -915 6
                l-916 0 12 -22z" />
                                                <path d="M9600 10717 c-45 -80 -114 -199 -287 -495 l-84 -144 37 -66 c20 -37
                42 -74 49 -82 7 -8 42 -69 78 -135 36 -66 87 -154 112 -195 25 -41 77 -129
                114 -195 107 -190 131 -225 152 -225 10 0 19 5 19 12 0 9 3 9 12 0 9 -9 140
                -12 529 -12 l517 0 45 73 c25 39 77 129 116 198 38 70 82 144 97 165 14 22 37
                62 51 89 31 62 96 173 138 240 18 28 42 69 54 92 l21 42 -83 143 c-174 296
                -242 415 -287 495 l-45 82 -655 0 -655 0 -45 -82z" />
                                                <path d="M11043 10788 c2 -7 44 -81 93 -165 49 -84 136 -233 194 -333 l105
                -180 519 2 520 3 34 60 c19 33 65 112 102 175 37 63 92 158 121 210 29 52 67
                118 84 145 18 28 37 60 44 73 l12 22 -917 0 c-729 0 -915 -3 -911 -12z" />
                                                <path d="M12914 10733 c-20 -38 -49 -86 -64 -108 -14 -22 -57 -96 -95 -165
                -38 -69 -80 -143 -95 -165 -15 -22 -48 -80 -74 -129 l-48 -89 16 -34 c9 -19
                50 -90 90 -157 40 -66 92 -157 116 -201 24 -44 57 -102 75 -130 17 -27 67
                -113 110 -190 43 -77 85 -150 94 -162 l16 -22 520 0 520 0 29 47 c16 26 42 72
                59 102 17 30 54 96 82 145 29 50 72 124 96 165 23 41 90 155 147 254 l105 178
                -43 82 c-24 44 -53 95 -65 111 -13 17 -50 81 -84 143 -35 62 -86 152 -115 200
                -29 48 -65 111 -80 140 l-26 52 -624 0 -624 0 -38 -67z" />
                                                <path d="M14280 10795 c0 -2 24 -44 53 -92 30 -48 88 -149 129 -223 42 -74 94
                -164 115 -200 22 -36 53 -89 69 -117 l29 -53 520 0 520 0 29 53 c16 28 55 95
                86 147 31 52 74 127 95 165 21 39 71 125 112 192 40 68 73 125 73 128 0 3
                -412 5 -915 5 -503 0 -915 -2 -915 -5z" />
                                                <path d="M16164 10748 c-15 -29 -51 -92 -80 -140 -29 -48 -81 -138 -115 -200
                -34 -62 -74 -131 -89 -153 -15 -22 -44 -72 -65 -111 l-38 -72 85 -143 c47 -79
                100 -171 118 -204 19 -33 59 -103 90 -155 31 -52 86 -146 121 -207 35 -62 73
                -129 84 -148 l20 -35 521 0 520 0 33 53 c17 28 63 106 100 172 104 183 187
                326 277 480 120 204 114 178 58 281 -26 49 -59 107 -74 129 -15 22 -57 96 -95
                165 -38 69 -81 143 -95 165 -15 22 -44 70 -64 108 l-38 67 -624 0 -624 0 -26
                -52z" />
                                                <path d="M17531 10778 c7 -13 26 -45 44 -73 17 -27 55 -93 84 -145 29 -52 84
                -147 121 -210 37 -63 83 -142 102 -175 l34 -60 520 -3 519 -2 70 117 c38 65
                93 161 122 213 l53 95 0 132 0 133 -840 0 -841 0 12 -22z" />
                                                <path d="M19107 10227 l-88 -149 45 -77 c24 -42 64 -110 88 -151 l43 -75 3
                153 c1 83 1 219 0 300 l-3 148 -88 -149z" />
                                                <path d="M1614 9988 c-15 -29 -41 -75 -59 -103 -17 -27 -67 -113 -110 -190
                -43 -77 -88 -153 -100 -170 -12 -16 -43 -70 -70 -120 -26 -49 -72 -130 -101
                -179 l-54 -89 34 -51 c18 -28 58 -96 88 -151 31 -55 80 -140 110 -190 30 -49
                76 -130 103 -180 27 -49 60 -108 75 -130 15 -22 46 -75 69 -117 l42 -78 522 0
                522 0 55 102 c30 56 70 124 88 150 17 26 32 50 32 52 0 5 126 225 177 311 17
                28 60 103 96 167 l67 117 -34 58 c-34 59 -112 195 -219 383 -33 58 -97 168
                -143 245 -45 77 -90 157 -100 178 l-18 37 -523 0 -523 0 -26 -52z" />
                                                <path d="M4860 9994 c-13 -25 -53 -94 -88 -153 -35 -58 -93 -158 -129 -221
                -35 -63 -109 -191 -164 -285 -122 -209 -115 -193 -99 -219 8 -11 52 -88 98
                -171 46 -82 92 -161 102 -175 9 -14 52 -88 95 -165 42 -77 83 -147 90 -155 7
                -8 36 -59 66 -112 l54 -98 520 0 520 0 54 98 c30 53 65 111 76 127 12 17 43
                71 70 120 26 50 74 133 105 185 32 52 86 147 122 210 35 63 70 125 78 138 13
                19 13 25 1 45 -26 41 -161 280 -161 284 0 3 -10 19 -22 36 -13 17 -59 95 -103
                172 -44 78 -111 196 -150 263 l-70 121 -520 1 -520 0 -25 -46z" />
                                                <path d="M8106 10003 c-10 -21 -55 -101 -100 -178 -78 -132 -126 -215 -269
                -465 -33 -58 -74 -131 -93 -162 l-33 -57 66 -118 c36 -65 79 -140 96 -168 47
                -78 177 -306 177 -311 0 -2 15 -26 33 -52 17 -26 57 -94 87 -150 l55 -102 522
                0 521 0 37 68 c21 37 53 92 72 122 35 57 147 252 284 494 44 77 85 147 93 155
                40 46 39 55 -18 148 -29 48 -75 129 -101 178 -27 50 -54 97 -61 105 -11 13
                -114 192 -257 443 l-49 87 -522 0 -522 0 -18 -37z" />
                                                <path d="M11404 9988 c-15 -29 -41 -75 -59 -103 -17 -27 -67 -113 -110 -190
                -43 -77 -88 -153 -100 -170 -12 -16 -43 -70 -70 -120 -26 -49 -72 -130 -101
                -179 l-54 -89 34 -51 c18 -28 58 -96 88 -151 31 -55 80 -140 110 -190 30 -49
                76 -130 103 -180 27 -49 60 -108 75 -130 15 -22 46 -75 69 -117 l42 -78 522 0
                522 0 55 102 c30 56 70 124 88 150 17 26 32 50 32 52 0 5 126 225 177 311 17
                28 60 103 96 167 l67 117 -34 58 c-34 59 -112 195 -219 383 -33 58 -97 168
                -143 245 -45 77 -90 157 -100 178 l-18 37 -523 0 -523 0 -26 -52z" />
                                                <path d="M14650 9994 c-13 -25 -53 -94 -88 -153 -35 -58 -93 -158 -129 -221
                -35 -63 -109 -191 -164 -285 -122 -209 -115 -193 -99 -219 8 -11 52 -88 98
                -171 46 -82 92 -161 102 -175 9 -14 52 -88 95 -165 42 -77 83 -147 90 -155 7
                -8 36 -59 66 -112 l54 -98 520 0 520 0 54 98 c30 53 65 111 76 127 12 17 43
                71 70 120 26 50 74 133 105 185 32 52 86 147 122 210 35 63 70 125 78 138 13
                19 13 25 1 45 -26 41 -161 280 -161 284 0 3 -10 19 -22 36 -13 17 -59 95 -103
                172 -44 78 -111 196 -150 263 l-70 121 -520 1 -520 0 -25 -46z" />
                                                <path d="M17896 10003 c-10 -21 -55 -101 -100 -178 -78 -132 -126 -215 -269
                -465 -33 -58 -74 -131 -93 -162 l-33 -57 66 -118 c36 -65 79 -140 96 -168 47
                -78 177 -306 177 -311 0 -2 15 -26 33 -52 17 -26 57 -94 87 -150 l55 -102 522
                0 522 0 42 78 c23 42 50 89 61 103 10 15 45 75 78 135 l60 108 0 477 0 477
                -72 124 c-39 67 -94 162 -121 211 l-49 87 -522 0 -522 0 -18 -37z" />
                                                <path d="M12 9090 c-16 -26 -18 -1740 -2 -1771 10 -18 27 -19 528 -19 l518 0
                80 138 c44 75 99 169 121 207 22 39 53 93 70 120 17 28 57 97 89 155 32 58 70
                123 85 145 14 22 38 63 52 90 l25 51 -43 74 c-24 41 -61 104 -81 140 -21 36
                -62 108 -92 160 -31 52 -82 142 -115 200 -33 58 -90 155 -126 217 l-66 112
                -515 1 c-503 0 -515 0 -528 -20z" />
                                                <path d="M3248 9073 c-13 -21 -48 -81 -78 -133 -30 -52 -68 -120 -86 -150 -18
                -30 -70 -120 -115 -200 -45 -80 -94 -163 -109 -185 -15 -22 -46 -76 -69 -121
                l-43 -81 21 -42 c12 -23 55 -97 95 -164 40 -67 92 -158 116 -202 24 -44 55
                -98 70 -120 15 -22 57 -96 95 -165 38 -69 82 -144 97 -167 l29 -43 513 0 513
                0 37 60 c20 32 72 121 115 197 43 76 88 154 100 173 13 19 53 89 90 155 38 66
                94 164 125 217 l57 96 -27 54 c-14 29 -50 91 -79 138 -28 47 -81 137 -117 200
                -89 157 -181 317 -246 428 l-54 92 -513 0 -512 0 -25 -37z" />
                                                <path d="M6458 9018 c-64 -109 -155 -267 -246 -428 -36 -63 -83 -144 -105
                -180 -22 -36 -58 -98 -79 -138 l-40 -73 58 -97 c31 -53 87 -151 125 -217 37
                -66 77 -136 90 -155 12 -19 49 -82 82 -140 32 -58 84 -147 114 -197 l55 -93
                513 0 513 0 24 33 c13 17 46 73 72 122 27 50 74 133 106 185 31 52 74 127 95
                165 21 39 71 125 111 192 40 67 83 141 95 164 l21 42 -43 81 c-23 45 -54 99
                -69 121 -15 22 -65 106 -111 188 -141 247 -253 442 -276 481 l-22 36 -515 0
                -514 0 -54 -92z" />
                                                <path d="M9689 8997 c-62 -106 -103 -176 -257 -447 -36 -63 -96 -166 -133
                -229 l-68 -114 26 -51 c14 -28 38 -69 52 -91 15 -22 53 -87 85 -145 32 -58 71
                -125 87 -150 15 -25 47 -79 70 -120 133 -238 204 -350 221 -350 10 0 18 7 18
                18 1 16 1 16 11 0 9 -17 45 -18 527 -18 l518 0 80 138 c44 75 99 169 121 207
                22 39 53 93 70 120 17 28 57 97 89 155 32 58 70 123 85 145 14 22 38 63 52 90
                l25 51 -43 74 c-24 41 -61 104 -81 140 -21 36 -62 108 -92 160 -31 52 -82 142
                -115 200 -33 58 -90 155 -126 217 l-66 112 -514 1 c-478 0 -515 -1 -527 -17
                -13 -17 -13 -17 -14 0 0 9 -8 17 -17 17 -12 0 -40 -39 -84 -113z" />
                                                <path d="M13038 9073 c-13 -21 -48 -81 -78 -133 -30 -52 -68 -120 -86 -150
                -18 -30 -70 -120 -115 -200 -45 -80 -94 -163 -109 -185 -15 -22 -46 -76 -69
                -121 l-43 -81 21 -42 c12 -23 55 -97 95 -164 40 -67 92 -158 116 -202 24 -44
                55 -98 70 -120 15 -22 57 -96 95 -165 38 -69 82 -144 97 -167 l29 -43 513 0
                513 0 37 60 c20 32 72 121 115 197 43 76 88 154 100 173 13 19 53 89 90 155
                38 66 94 164 125 217 l57 96 -27 54 c-14 29 -50 91 -79 138 -28 47 -81 137
                -117 200 -89 157 -181 317 -246 428 l-54 92 -513 0 -512 0 -25 -37z" />
                                                <path d="M16248 9018 c-64 -109 -155 -267 -246 -428 -36 -63 -83 -144 -105
                -180 -22 -36 -58 -98 -79 -138 l-40 -73 58 -97 c31 -53 87 -151 125 -217 37
                -66 77 -136 90 -155 12 -19 49 -82 82 -140 32 -58 84 -147 114 -197 l55 -93
                513 0 513 0 24 33 c13 17 46 73 72 122 27 50 74 133 106 185 31 52 74 127 95
                165 21 39 71 125 111 192 40 67 83 141 95 164 l21 42 -43 81 c-23 45 -54 99
                -69 121 -15 22 -65 106 -111 188 -141 247 -253 442 -276 481 l-22 36 -515 0
                -514 0 -54 -92z" />
                                                <path d="M19186 8492 c-4 -10 -42 -78 -85 -152 l-80 -133 26 -50 c14 -28 40
                -73 58 -101 18 -28 46 -76 63 -106 l32 -55 0 308 c0 169 -2 307 -4 307 -3 0
                -7 -8 -10 -18z" />
                                                <path d="M1610 8108 c-18 -35 -47 -85 -65 -113 -18 -27 -53 -88 -80 -135 -26
                -47 -78 -137 -115 -200 -37 -63 -94 -162 -127 -220 -33 -58 -69 -121 -81 -140
                l-22 -36 25 -36 c13 -19 62 -102 108 -184 109 -193 232 -406 270 -469 17 -27
                50 -85 74 -127 l44 -78 522 0 522 0 34 63 c28 52 76 134 126 216 6 9 39 68 74
                131 35 63 78 138 96 165 17 28 60 102 95 165 35 63 69 124 76 135 12 18 4 37
                -74 175 -48 85 -100 175 -115 200 -15 25 -59 99 -96 165 -71 125 -193 337
                -211 368 -9 16 -45 17 -529 17 l-518 0 -33 -62z" />
                                                <path d="M4824 8062 c-33 -59 -91 -161 -129 -227 -37 -66 -89 -154 -114 -195
                -25 -41 -57 -95 -70 -120 -13 -25 -51 -92 -84 -149 -52 -91 -58 -107 -47 -124
                7 -11 49 -86 95 -166 151 -266 254 -445 289 -501 19 -30 53 -90 77 -132 l43
                -78 521 0 521 0 43 78 c24 42 59 102 78 132 33 52 103 175 288 501 46 80 89
                155 96 167 11 17 11 26 2 40 -7 9 -43 71 -80 137 -81 143 -86 152 -139 241
                -43 72 -126 216 -228 396 l-61 107 -520 0 -520 0 -61 -107z" />
                                                <path d="M8120 8153 c-24 -41 -148 -258 -222 -388 -44 -77 -87 -151 -96 -165
                -9 -14 -56 -95 -104 -180 -78 -138 -86 -157 -74 -175 7 -11 41 -72 76 -135 34
                -63 72 -128 83 -144 19 -28 177 -305 177 -311 0 -2 15 -25 33 -52 17 -27 54
                -90 82 -141 l49 -92 523 0 522 0 44 78 c24 42 57 100 74 127 38 63 161 276
                270 469 46 82 95 165 108 184 l25 36 -22 36 c-12 19 -48 82 -81 140 -33 58
                -90 157 -127 220 -100 169 -183 313 -240 418 l-52 92 -519 0 c-484 0 -520 -1
                -529 -17z" />
                                                <path d="M11400 8108 c-18 -35 -47 -85 -65 -113 -18 -27 -53 -88 -80 -135 -26
                -47 -78 -137 -115 -200 -37 -63 -94 -162 -127 -220 -33 -58 -69 -121 -81 -140
                l-22 -36 25 -36 c13 -19 62 -102 108 -184 109 -193 232 -406 270 -469 17 -27
                50 -85 74 -127 l44 -78 522 0 522 0 34 63 c28 52 76 134 126 216 6 9 39 68 74
                131 35 63 78 138 96 165 17 28 60 102 95 165 35 63 69 124 76 135 12 18 4 37
                -74 175 -48 85 -100 175 -115 200 -15 25 -59 99 -96 165 -71 125 -193 337
                -211 368 -9 16 -45 17 -529 17 l-518 0 -33 -62z" />
                                                <path d="M14614 8062 c-33 -59 -91 -161 -129 -227 -37 -66 -89 -154 -114 -195
                -25 -41 -57 -95 -70 -120 -13 -25 -51 -92 -84 -149 -52 -91 -58 -107 -47 -124
                7 -11 49 -86 95 -166 151 -266 254 -445 289 -501 19 -30 53 -90 77 -132 l43
                -78 521 0 521 0 43 78 c24 42 59 102 78 132 33 52 103 175 288 501 46 80 89
                155 96 167 11 17 11 26 2 40 -7 9 -43 71 -80 137 -81 143 -86 152 -139 241
                -43 72 -126 216 -228 396 l-61 107 -520 0 -520 0 -61 -107z" />
                                                <path d="M17910 8153 c-24 -41 -148 -258 -222 -388 -44 -77 -87 -151 -96 -165
                -9 -14 -56 -95 -104 -180 -78 -138 -86 -157 -74 -175 7 -11 41 -72 76 -135 34
                -63 72 -128 83 -144 19 -28 177 -305 177 -311 0 -2 15 -25 33 -52 17 -27 54
                -90 82 -141 l49 -92 523 0 522 0 37 68 c21 37 45 79 55 93 10 14 47 78 84 143
                l65 117 0 477 0 477 -70 120 c-38 66 -92 162 -120 213 l-52 92 -519 0 c-484 0
                -520 -1 -529 -17z" />
                                                <path d="M12 7218 c-17 -17 -17 -1759 0 -1776 9 -9 140 -12 529 -12 l517 0 74
                128 c41 70 92 159 113 197 21 39 52 91 69 116 17 26 67 111 111 189 44 78 97
                171 117 207 l37 65 -31 56 c-17 31 -44 77 -60 102 -28 43 -131 222 -260 450
                -33 58 -84 147 -115 198 l-56 92 -516 0 c-389 0 -520 -3 -529 -12z" />
                                                <path d="M3251 7208 c-8 -13 -53 -90 -99 -173 -47 -82 -92 -161 -102 -175 -10
                -14 -55 -91 -101 -173 -46 -81 -110 -193 -142 -250 l-59 -102 22 -45 c12 -25
                59 -108 105 -185 46 -77 103 -175 127 -217 114 -201 209 -367 235 -410 l28
                -48 517 0 516 0 39 63 c22 34 68 114 103 177 35 63 73 129 85 145 12 17 43 71
                69 120 27 50 81 144 121 210 115 191 110 176 79 237 -15 29 -38 71 -53 93 -24
                36 -110 186 -265 457 -32 57 -84 146 -115 198 l-57 95 -519 3 -520 2 -14 -22z" />
                                                <path d="M6477 7178 c-18 -29 -62 -105 -97 -168 -34 -63 -77 -137 -95 -165
                -18 -27 -60 -102 -95 -165 -35 -63 -82 -144 -105 -180 -23 -36 -54 -89 -69
                -118 -31 -61 -36 -46 79 -237 40 -66 94 -160 121 -210 26 -49 57 -103 69 -120
                12 -16 50 -82 85 -145 35 -63 81 -143 103 -177 l39 -63 516 0 517 0 19 33 c11
                17 54 93 96 168 42 75 86 149 97 165 11 16 39 65 63 109 24 44 77 136 118 205
                41 69 86 147 99 175 l25 50 -59 102 c-32 57 -96 169 -142 250 -46 82 -91 159
                -101 173 -10 14 -55 93 -102 175 -46 83 -91 160 -99 173 l-14 22 -517 0 -517
                0 -34 -52z" />
                                                <path d="M9683 7113 c-72 -123 -148 -254 -264 -458 -38 -66 -81 -140 -96 -165
                -15 -25 -42 -71 -60 -102 l-32 -56 31 -59 c17 -32 37 -65 44 -73 10 -13 104
                -177 163 -285 9 -17 25 -44 37 -60 12 -17 38 -61 59 -100 107 -196 189 -325
                206 -325 11 0 19 5 19 12 0 9 3 9 12 0 9 -9 140 -12 529 -12 l517 0 74 128
                c41 70 92 159 113 197 21 39 52 91 69 116 17 26 67 111 111 189 44 78 97 171
                117 207 l37 65 -31 56 c-17 31 -44 77 -60 102 -28 43 -131 222 -260 450 -33
                58 -84 147 -115 198 l-56 92 -516 0 c-389 0 -520 -3 -529 -12 -9 -9 -12 -9
                -12 0 0 7 -8 12 -19 12 -13 0 -39 -34 -88 -117z" />
                                                <path d="M13041 7208 c-8 -13 -53 -90 -99 -173 -47 -82 -92 -161 -102 -175
                -10 -14 -55 -91 -101 -173 -46 -81 -110 -193 -142 -250 l-59 -102 22 -45 c12
                -25 59 -108 105 -185 46 -77 103 -175 127 -217 114 -201 209 -367 235 -410
                l28 -48 517 0 516 0 39 63 c22 34 68 114 103 177 35 63 73 129 85 145 12 17
                43 71 69 120 27 50 81 144 121 210 115 191 110 176 79 237 -15 29 -38 71 -53
                93 -24 36 -110 186 -265 457 -32 57 -84 146 -115 198 l-57 95 -519 3 -520 2
                -14 -22z" />
                                                <path d="M16267 7178 c-18 -29 -62 -105 -97 -168 -34 -63 -77 -137 -95 -165
                -18 -27 -60 -102 -95 -165 -35 -63 -82 -144 -105 -180 -23 -36 -54 -89 -69
                -118 -31 -61 -36 -46 79 -237 40 -66 94 -160 121 -210 26 -49 57 -103 69 -120
                12 -16 50 -82 85 -145 35 -63 81 -143 103 -177 l39 -63 516 0 517 0 19 33 c11
                17 54 93 96 168 42 75 86 149 97 165 11 16 39 65 63 109 24 44 77 136 118 205
                41 69 86 147 99 175 l25 50 -59 102 c-32 57 -96 169 -142 250 -46 82 -91 159
                -101 173 -10 14 -55 93 -102 175 -46 83 -91 160 -99 173 l-14 22 -517 0 -517
                0 -34 -52z" />
                                                <path d="M19164 6578 c-15 -29 -41 -75 -59 -103 -17 -27 -44 -71 -58 -96 l-27
                -47 38 -65 c54 -94 94 -163 119 -205 l22 -37 1 303 c0 166 -2 302 -5 302 -2 0
                -17 -24 -31 -52z" />
                                                <path d="M1606 6228 c-22 -40 -49 -86 -60 -103 -12 -16 -47 -77 -79 -135 -32
                -58 -89 -157 -126 -220 -119 -202 -212 -365 -213 -373 -1 -8 76 -145 213 -377
                37 -63 97 -167 133 -230 36 -63 89 -157 118 -208 l53 -92 519 2 518 3 67 116
                c36 64 101 179 145 255 43 77 93 162 111 189 47 73 188 328 188 340 0 12 -141
                267 -188 340 -18 28 -68 112 -111 189 -44 76 -109 191 -145 255 l-67 116 -519
                3 -519 2 -38 -72z" />
                                                <path d="M4845 6223 c-22 -42 -54 -97 -70 -122 -17 -25 -61 -102 -99 -171 -38
                -69 -88 -154 -111 -190 -22 -36 -54 -90 -70 -120 -15 -30 -51 -93 -79 -140
                l-51 -85 60 -102 c33 -56 77 -133 98 -170 81 -144 207 -360 240 -414 20 -31
                55 -93 79 -137 l43 -82 520 0 520 0 43 82 c24 44 59 106 79 137 34 54 160 273
                240 414 21 38 65 114 98 170 l60 102 -60 102 c-33 56 -77 133 -98 170 -81 144
                -207 360 -240 414 -20 31 -55 93 -79 137 l-43 82 -520 0 -520 0 -40 -77z" />
                                                <path d="M8102 6249 c-15 -27 -66 -116 -113 -197 -47 -81 -112 -192 -143 -247
                -31 -55 -97 -170 -146 -255 l-88 -155 23 -40 c12 -22 53 -94 90 -160 37 -66
                71 -125 75 -130 5 -6 44 -73 88 -150 43 -77 105 -185 137 -240 32 -55 69 -119
                82 -142 l24 -43 517 0 517 0 34 63 c19 34 49 85 66 112 29 46 78 131 275 478
                42 72 92 159 113 191 l37 60 -25 40 c-14 23 -50 86 -82 141 -31 55 -71 125
                -89 155 -18 30 -70 120 -115 200 -45 80 -97 168 -114 195 -17 28 -47 78 -66
                113 l-34 62 -517 0 -518 0 -28 -51z" />
                                                <path d="M11396 6228 c-22 -40 -49 -86 -60 -103 -12 -16 -47 -77 -79 -135 -32
                -58 -89 -157 -126 -220 -119 -202 -212 -365 -213 -373 -1 -8 76 -145 213 -377
                37 -63 97 -167 133 -230 36 -63 89 -157 118 -208 l53 -92 519 2 518 3 67 116
                c36 64 101 179 145 255 43 77 93 162 111 189 47 73 188 328 188 340 0 12 -141
                267 -188 340 -18 28 -68 112 -111 189 -44 76 -109 191 -145 255 l-67 116 -519
                3 -519 2 -38 -72z" />
                                                <path d="M14635 6223 c-22 -42 -54 -97 -70 -122 -17 -25 -61 -102 -99 -171
                -38 -69 -88 -154 -111 -190 -22 -36 -54 -90 -70 -120 -15 -30 -51 -93 -79
                -140 l-51 -85 60 -102 c33 -56 77 -133 98 -170 81 -144 207 -360 240 -414 20
                -31 55 -93 79 -137 l43 -82 520 0 520 0 43 82 c24 44 59 106 79 137 34 54 160
                273 240 414 21 38 65 114 98 170 l60 102 -60 102 c-33 56 -77 133 -98 170 -81
                144 -207 360 -240 414 -20 31 -55 93 -79 137 l-43 82 -520 0 -520 0 -40 -77z" />
                                                <path d="M17892 6249 c-15 -27 -66 -116 -113 -197 -47 -81 -112 -192 -143
                -247 -31 -55 -97 -170 -146 -255 l-88 -155 23 -40 c12 -22 53 -94 90 -160 37
                -66 71 -125 75 -130 5 -6 44 -73 88 -150 43 -77 105 -185 137 -240 32 -55 69
                -119 82 -142 l24 -43 517 0 517 0 53 93 c29 50 84 146 122 212 l69 120 0 480
                0 480 -69 120 c-38 66 -93 162 -122 212 l-53 93 -518 0 -517 0 -28 -51z" />
                                                <path d="M12 5348 c-17 -17 -17 -1759 0 -1776 9 -9 140 -12 529 -12 l517 0 70
                118 c38 64 77 132 87 151 9 18 49 88 89 155 40 66 99 168 131 226 31 58 61
                107 64 110 4 3 24 35 44 72 l36 67 -37 65 c-20 35 -73 128 -117 206 -44 78
                -94 163 -111 189 -17 25 -48 78 -69 116 -21 39 -72 127 -113 198 l-74 127
                -517 0 c-389 0 -520 -3 -529 -12z" />
                                                <path d="M3237 5312 c-26 -43 -121 -209 -235 -410 -24 -42 -81 -140 -127 -217
                -45 -77 -93 -160 -105 -184 l-22 -44 28 -51 c15 -28 55 -98 90 -156 34 -58 91
                -155 126 -217 166 -292 223 -391 247 -431 l26 -42 520 2 519 3 57 95 c31 52
                83 141 115 198 155 271 241 421 265 457 15 22 38 64 53 93 31 61 36 46 -79
                237 -40 66 -94 161 -121 210 -26 50 -57 104 -69 120 -12 17 -50 82 -85 145
                -35 63 -81 143 -103 178 l-39 62 -516 0 -517 0 -28 -48z" />
                                                <path d="M6473 5298 c-22 -35 -68 -115 -103 -178 -35 -63 -73 -128 -85 -145
                -12 -16 -43 -70 -69 -120 -27 -49 -81 -144 -121 -210 -115 -191 -110 -176 -79
                -237 15 -29 42 -76 60 -104 19 -28 71 -116 116 -196 152 -267 201 -352 257
                -448 l57 -95 519 -3 520 -2 14 22 c8 13 53 90 99 173 47 82 92 161 102 175 10
                14 55 91 101 173 46 81 110 193 142 250 l59 102 -25 50 c-13 28 -58 106 -99
                175 -41 69 -94 161 -118 205 -24 44 -52 93 -63 109 -11 16 -55 90 -97 165 -42
                75 -85 151 -96 168 l-19 33 -517 0 -516 0 -39 -62z" />
                                                <path d="M9710 5288 c-24 -40 -79 -134 -121 -208 -161 -284 -249 -436 -271
                -470 -13 -19 -37 -61 -55 -93 l-32 -59 32 -56 c18 -32 45 -77 60 -102 15 -25
                58 -99 96 -165 278 -489 331 -575 352 -575 11 0 19 5 19 12 0 9 3 9 12 0 9 -9
                140 -12 529 -12 l517 0 70 118 c38 64 77 132 87 151 9 18 49 88 89 155 40 66
                99 168 131 226 31 58 61 107 64 110 4 3 24 35 44 72 l36 67 -37 65 c-20 35
                -73 128 -117 206 -44 78 -94 163 -111 189 -17 25 -48 78 -69 116 -21 39 -72
                127 -113 198 l-74 127 -517 0 c-389 0 -520 -3 -529 -12 -9 -9 -12 -9 -12 0 0
                7 -8 12 -18 12 -13 0 -34 -24 -62 -72z" />
                                                <path d="M13027 5312 c-26 -43 -121 -209 -235 -410 -24 -42 -81 -140 -127
                -217 -45 -77 -93 -160 -105 -184 l-22 -44 28 -51 c15 -28 55 -98 90 -156 34
                -58 91 -155 126 -217 166 -292 223 -391 247 -431 l26 -42 520 2 519 3 57 95
                c31 52 83 141 115 198 155 271 241 421 265 457 15 22 38 64 53 93 31 61 36 46
                -79 237 -40 66 -94 161 -121 210 -26 50 -57 104 -69 120 -12 17 -50 82 -85
                145 -35 63 -81 143 -103 178 l-39 62 -516 0 -517 0 -28 -48z" />
                                                <path d="M16263 5298 c-22 -35 -68 -115 -103 -178 -35 -63 -73 -128 -85 -145
                -12 -16 -43 -70 -69 -120 -27 -49 -81 -144 -121 -210 -115 -191 -110 -176 -79
                -237 15 -29 42 -76 60 -104 19 -28 71 -116 116 -196 152 -267 201 -352 257
                -448 l57 -95 519 -3 520 -2 14 22 c8 13 53 90 99 173 47 82 92 161 102 175 10
                14 55 91 101 173 46 81 110 193 142 250 l59 102 -25 50 c-13 28 -58 106 -99
                175 -41 69 -94 161 -118 205 -24 44 -52 93 -63 109 -11 16 -55 90 -97 165 -42
                75 -85 151 -96 168 l-19 33 -517 0 -516 0 -39 -62z" />
                                                <path d="M19160 4698 c-18 -35 -43 -76 -55 -93 -12 -16 -35 -56 -53 -88 l-31
                -58 36 -62 c62 -110 122 -212 132 -227 7 -10 10 86 10 288 1 166 -1 302 -3
                302 -2 0 -18 -28 -36 -62z" />
                                                <path d="M1597 4343 c-24 -43 -57 -100 -74 -128 -38 -63 -161 -276 -270 -469
                -46 -82 -95 -165 -108 -184 l-25 -36 22 -36 c12 -19 48 -82 81 -140 33 -58 90
                -157 127 -220 37 -63 89 -153 115 -200 27 -47 62 -107 80 -135 18 -27 47 -78
                65 -112 l33 -63 518 0 c484 0 520 1 529 18 18 30 140 242 211 367 37 66 81
                140 96 165 15 25 67 115 114 200 78 137 86 157 75 175 -7 11 -41 72 -76 135
                -35 63 -78 138 -95 165 -18 28 -61 102 -96 165 -35 63 -68 122 -74 131 -50 82
                -98 164 -126 217 l-34 62 -522 0 -522 0 -44 -77z" />
                                                <path d="M4855 4368 c-16 -29 -46 -82 -68 -118 -22 -36 -69 -117 -104 -180
                -36 -63 -92 -159 -124 -212 -32 -54 -59 -100 -59 -102 0 -3 -29 -55 -65 -117
                -36 -61 -65 -113 -65 -116 0 -2 26 -50 58 -106 33 -56 73 -127 89 -157 17 -30
                51 -89 76 -130 51 -85 149 -255 235 -407 l57 -102 520 0 520 0 61 107 c33 59
                91 161 129 227 37 66 89 154 114 195 25 41 54 91 65 110 10 19 48 86 83 149
                58 100 64 117 53 134 -7 11 -37 66 -68 121 -31 56 -88 155 -128 221 -39 66
                -93 161 -120 210 -26 50 -58 104 -70 120 -12 17 -43 69 -70 118 l-48 87 -521
                0 -522 0 -28 -52z" />
                                                <path d="M8075 4328 c-28 -51 -65 -114 -82 -141 -18 -27 -33 -50 -33 -52 0 -6
                -158 -283 -177 -311 -11 -16 -49 -81 -83 -144 -35 -63 -69 -124 -76 -135 -12
                -18 -4 -37 74 -175 48 -85 100 -175 115 -200 15 -25 59 -99 96 -165 71 -125
                193 -337 211 -367 9 -17 45 -18 529 -18 l519 0 52 93 c57 104 140 248 240 417
                37 63 94 162 127 220 33 58 69 121 81 140 l22 36 -25 36 c-13 19 -62 102 -108
                184 -109 193 -232 406 -270 469 -17 28 -50 85 -74 128 l-44 77 -522 0 -523 0
                -49 -92z" />
                                                <path d="M11387 4343 c-24 -43 -57 -100 -74 -128 -38 -63 -161 -276 -270 -469
                -46 -82 -95 -165 -108 -184 l-25 -36 22 -36 c12 -19 48 -82 81 -140 33 -58 90
                -157 127 -220 37 -63 89 -153 115 -200 27 -47 62 -107 80 -135 18 -27 47 -78
                65 -112 l33 -63 518 0 c484 0 520 1 529 18 18 30 140 242 211 367 37 66 81
                140 96 165 15 25 67 115 114 200 78 137 86 157 75 175 -7 11 -41 72 -76 135
                -35 63 -78 138 -95 165 -18 28 -61 102 -96 165 -35 63 -68 122 -74 131 -50 82
                -98 164 -126 217 l-34 62 -522 0 -522 0 -44 -77z" />
                                                <path d="M14645 4368 c-16 -29 -46 -82 -68 -118 -22 -36 -69 -117 -104 -180
                -36 -63 -92 -159 -124 -212 -32 -54 -59 -100 -59 -102 0 -3 -29 -55 -65 -117
                -36 -61 -65 -113 -65 -116 0 -2 26 -50 58 -106 33 -56 73 -127 89 -157 17 -30
                51 -89 76 -130 51 -85 149 -255 235 -407 l57 -102 520 0 520 0 61 107 c33 59
                91 161 129 227 37 66 89 154 114 195 25 41 54 91 65 110 10 19 48 86 83 149
                58 100 64 117 53 134 -7 11 -37 66 -68 121 -31 56 -88 155 -128 221 -39 66
                -93 161 -120 210 -26 50 -58 104 -70 120 -12 17 -43 69 -70 118 l-48 87 -521
                0 -522 0 -28 -52z" />
                                                <path d="M17865 4328 c-28 -51 -65 -114 -82 -141 -18 -27 -33 -50 -33 -52 0
                -6 -158 -283 -177 -311 -11 -16 -49 -81 -83 -144 -35 -63 -69 -124 -76 -135
                -12 -18 -4 -37 74 -175 48 -85 100 -175 115 -200 15 -25 59 -99 96 -165 71
                -125 193 -337 211 -367 9 -17 45 -18 529 -18 l519 0 52 93 c28 50 82 146 120
                212 l70 120 0 477 0 477 -65 117 c-37 65 -74 129 -84 143 -10 14 -34 56 -55
                94 l-37 67 -522 0 -523 0 -49 -92z" />
                                                <path d="M10 3471 c-7 -13 -10 -308 -10 -888 1 -706 3 -871 14 -885 12 -17 49
                -18 527 -18 l514 1 66 112 c36 62 93 159 126 217 33 58 84 148 115 200 30 52
                71 124 92 160 20 36 57 99 81 140 l43 74 -25 51 c-14 27 -38 68 -52 90 -15 22
                -53 87 -85 145 -32 58 -72 128 -89 155 -17 28 -48 82 -70 120 -22 39 -77 132
                -121 208 l-80 137 -518 0 c-501 0 -518 -1 -528 -19z" />
                                                <path d="M3251 3461 c-11 -15 -58 -95 -104 -177 -46 -82 -97 -171 -114 -199
                -32 -52 -149 -255 -236 -408 l-50 -89 38 -71 c21 -40 52 -94 69 -121 32 -52
                177 -302 243 -419 21 -38 68 -119 104 -180 l66 -112 515 -3 516 -2 54 92 c65
                111 157 271 246 428 36 63 89 153 117 200 29 47 65 109 79 138 l27 54 -57 96
                c-31 53 -87 151 -125 217 -37 66 -77 136 -90 155 -12 19 -57 97 -100 173 -43
                76 -95 165 -115 197 l-37 60 -513 0 -513 0 -20 -29z" />
                                                <path d="M6457 3398 c-30 -51 -82 -140 -114 -198 -33 -58 -70 -121 -82 -140
                -13 -19 -53 -89 -90 -155 -38 -66 -94 -164 -125 -217 l-57 -96 27 -54 c14 -29
                50 -91 79 -138 28 -47 81 -137 117 -200 89 -157 181 -317 246 -427 l54 -93
                514 0 515 0 22 36 c23 39 135 234 276 481 46 82 96 166 111 188 15 22 46 76
                69 121 l43 81 -21 42 c-12 23 -55 97 -95 164 -40 67 -90 154 -111 192 -21 39
                -64 113 -95 165 -32 52 -79 136 -106 185 -26 50 -59 105 -72 123 l-24 32 -513
                0 -513 0 -55 -92z" />
                                                <path d="M9674 3353 c-45 -76 -98 -169 -119 -208 -21 -38 -49 -84 -62 -101
                -12 -17 -23 -33 -23 -37 0 -7 -146 -263 -169 -296 -10 -14 -30 -49 -44 -76
                l-25 -51 43 -74 c24 -41 61 -104 81 -140 21 -36 62 -108 92 -160 31 -52 82
                -142 115 -200 148 -260 192 -329 210 -330 9 0 17 8 17 18 1 16 1 16 14 0 12
                -17 49 -18 527 -18 l514 1 66 112 c36 62 93 159 126 217 33 58 84 148 115 200
                30 52 71 124 92 160 20 36 57 99 81 140 l43 74 -25 51 c-14 27 -38 68 -52 90
                -15 22 -53 87 -85 145 -32 58 -72 128 -89 155 -17 28 -48 82 -70 120 -22 39
                -77 132 -121 208 l-80 137 -518 0 c-482 0 -518 -1 -527 -17 -10 -17 -10 -17
                -11 0 0 10 -8 17 -18 17 -12 0 -42 -42 -98 -137z" />
                                                <path d="M13041 3461 c-11 -15 -58 -95 -104 -177 -46 -82 -97 -171 -114 -199
                -32 -52 -149 -255 -236 -408 l-50 -89 38 -71 c21 -40 52 -94 69 -121 32 -52
                177 -302 243 -419 21 -38 68 -119 104 -180 l66 -112 515 -3 516 -2 54 92 c65
                111 157 271 246 428 36 63 89 153 117 200 29 47 65 109 79 138 l27 54 -57 96
                c-31 53 -87 151 -125 217 -37 66 -77 136 -90 155 -12 19 -57 97 -100 173 -43
                76 -95 165 -115 197 l-37 60 -513 0 -513 0 -20 -29z" />
                                                <path d="M16247 3398 c-30 -51 -82 -140 -114 -198 -33 -58 -70 -121 -82 -140
                -13 -19 -53 -89 -90 -155 -38 -66 -94 -164 -125 -217 l-57 -96 27 -54 c14 -29
                50 -91 79 -138 28 -47 81 -137 117 -200 89 -157 181 -317 246 -427 l54 -93
                514 0 515 0 22 36 c23 39 135 234 276 481 46 82 96 166 111 188 15 22 46 76
                69 121 l43 81 -21 42 c-12 23 -55 97 -95 164 -40 67 -90 154 -111 192 -21 39
                -64 113 -95 165 -32 52 -79 136 -106 185 -26 50 -59 105 -72 123 l-24 32 -513
                0 -513 0 -55 -92z" />
                                                <path d="M19161 2828 c-19 -35 -44 -76 -55 -93 -11 -16 -35 -57 -53 -89 l-32
                -60 36 -66 c20 -36 42 -72 49 -80 7 -8 31 -49 53 -90 l41 -75 0 308 c0 169 -1
                307 -3 307 -1 0 -18 -28 -36 -62z" />
                                                <path d="M1599 2473 c-23 -43 -54 -96 -69 -118 -15 -22 -48 -80 -74 -128 -26
                -48 -82 -146 -124 -217 -42 -71 -99 -170 -127 -220 -27 -49 -52 -90 -56 -90
                -3 0 -11 -11 -17 -24 -10 -22 -5 -35 42 -113 29 -48 75 -128 101 -178 27 -49
                58 -103 70 -120 12 -16 57 -93 100 -170 43 -77 93 -162 110 -190 18 -27 44
                -74 59 -102 l26 -53 523 0 523 0 18 38 c10 20 55 100 100 177 46 77 110 187
                143 245 107 188 185 324 219 383 l34 58 -67 117 c-36 64 -79 140 -96 167 -48
                81 -177 306 -177 310 0 3 -20 35 -43 72 -24 37 -64 105 -88 150 l-44 83 -522
                0 -522 0 -42 -77z" />
                                                <path d="M4831 2452 c-30 -53 -60 -104 -66 -112 -7 -8 -48 -78 -90 -155 -43
                -77 -86 -151 -95 -165 -10 -14 -56 -92 -102 -175 -46 -82 -91 -160 -98 -172
                -13 -20 -13 -26 3 -50 15 -25 106 -183 234 -408 26 -44 83 -143 128 -220 46
                -77 96 -164 111 -192 l29 -53 520 0 520 1 70 121 c39 67 106 185 150 263 44
                77 90 155 103 172 12 17 22 33 22 36 0 5 133 240 161 285 12 19 12 25 -1 45
                -8 12 -43 74 -78 137 -36 63 -90 158 -122 210 -31 52 -79 136 -105 185 -27 50
                -58 104 -70 120 -11 17 -46 74 -76 127 l-54 98 -520 0 -520 0 -54 -98z" />
                                                <path d="M8070 2448 c-30 -56 -70 -124 -87 -150 -18 -26 -33 -50 -33 -52 0 -5
                -130 -233 -177 -311 -17 -27 -60 -103 -96 -168 l-66 -118 33 -57 c19 -31 60
                -104 93 -162 143 -250 191 -333 269 -465 45 -77 90 -157 100 -177 l18 -38 522
                0 522 0 49 88 c143 250 246 429 257 442 7 8 34 56 61 105 26 50 72 130 101
                178 47 77 53 91 43 110 -7 12 -15 24 -19 27 -4 3 -28 41 -52 85 -25 44 -65
                114 -89 155 -23 41 -80 140 -125 220 -46 80 -99 170 -117 200 -19 30 -51 85
                -72 123 l-37 67 -521 0 -522 0 -55 -102z" />
                                                <path d="M11389 2473 c-23 -43 -54 -96 -69 -118 -15 -22 -48 -80 -74 -128 -26
                -48 -82 -146 -124 -217 -42 -71 -99 -170 -127 -220 -27 -49 -52 -90 -56 -90
                -3 0 -11 -11 -17 -24 -10 -22 -5 -35 42 -113 29 -48 75 -128 101 -178 27 -49
                58 -103 70 -120 12 -16 57 -93 100 -170 43 -77 93 -162 110 -190 18 -27 44
                -74 59 -102 l26 -53 523 0 523 0 18 38 c10 20 55 100 100 177 46 77 110 187
                143 245 107 188 185 324 219 383 l34 58 -67 117 c-36 64 -79 140 -96 167 -48
                81 -177 306 -177 310 0 3 -20 35 -43 72 -24 37 -64 105 -88 150 l-44 83 -522
                0 -522 0 -42 -77z" />
                                                <path d="M14621 2452 c-30 -53 -60 -104 -66 -112 -7 -8 -48 -78 -90 -155 -43
                -77 -86 -151 -95 -165 -10 -14 -56 -92 -102 -175 -46 -82 -91 -160 -98 -172
                -13 -20 -13 -26 3 -50 15 -25 106 -183 234 -408 26 -44 83 -143 128 -220 46
                -77 96 -164 111 -192 l29 -53 520 0 520 1 70 121 c39 67 106 185 150 263 44
                77 90 155 103 172 12 17 22 33 22 36 0 5 133 240 161 285 12 19 12 25 -1 45
                -8 12 -43 74 -78 137 -36 63 -90 158 -122 210 -31 52 -79 136 -105 185 -27 50
                -58 104 -70 120 -11 17 -46 74 -76 127 l-54 98 -520 0 -520 0 -54 -98z" />
                                                <path d="M17860 2448 c-30 -56 -70 -124 -87 -150 -18 -26 -33 -50 -33 -52 0
                -5 -130 -233 -177 -311 -17 -27 -60 -103 -96 -168 l-66 -118 33 -57 c19 -31
                60 -104 93 -162 143 -250 191 -333 269 -465 45 -77 90 -157 100 -177 l18 -38
                522 0 522 0 49 88 c27 48 82 143 121 210 l72 124 0 477 0 477 -60 108 c-33 60
                -68 120 -78 135 -11 14 -38 61 -61 104 l-42 77 -522 0 -522 0 -55 -102z" />
                                                <path d="M12 1598 c-9 -9 -12 -202 -12 -805 l0 -793 584 0 584 0 44 78 c50 87
                160 277 285 490 l83 143 -21 42 c-12 23 -36 65 -54 92 -42 67 -107 178 -138
                240 -14 28 -37 67 -51 89 -15 21 -59 95 -97 165 -39 69 -91 159 -116 199 l-45
                72 -517 0 c-389 0 -520 -3 -529 -12z" />
                                                <path d="M3249 1587 c-9 -12 -51 -85 -94 -162 -43 -77 -93 -162 -110 -190 -18
                -27 -51 -86 -75 -130 -24 -44 -76 -135 -116 -201 -40 -67 -81 -138 -90 -157
                l-16 -34 47 -89 c27 -49 57 -102 68 -117 11 -16 57 -94 102 -175 45 -81 106
                -185 134 -232 28 -47 51 -88 51 -92 0 -5 285 -8 633 -8 l633 0 22 45 c12 25
                47 86 76 135 30 49 82 140 117 202 34 62 71 127 84 143 12 17 41 67 65 112
                l43 81 -85 144 c-47 78 -109 185 -138 237 -28 51 -62 110 -75 130 -13 20 -59
                99 -103 176 -43 77 -87 155 -98 172 l-19 33 -520 0 -520 -1 -16 -22z" />
                                                <path d="M6485 1575 c-11 -19 -49 -86 -84 -148 -35 -61 -90 -155 -121 -207
                -31 -52 -71 -122 -90 -155 -18 -33 -71 -125 -118 -204 l-85 -143 38 -72 c21
                -39 50 -89 65 -111 15 -22 54 -89 88 -150 33 -60 89 -158 124 -217 34 -58 71
                -120 80 -137 l17 -31 631 0 c346 0 630 3 630 8 0 4 23 45 51 92 28 47 89 151
                134 232 45 81 91 159 102 175 11 15 41 68 67 117 56 103 62 77 -58 281 -90
                154 -173 297 -277 480 -37 66 -83 144 -100 173 l-33 52 -520 0 -521 0 -20 -35z" />
                                                <path d="M9707 1538 c-25 -40 -77 -131 -117 -203 -40 -71 -78 -137 -85 -145
                -7 -8 -40 -65 -74 -126 -33 -61 -74 -133 -90 -160 -16 -27 -48 -81 -71 -121
                l-41 -72 84 -143 c92 -157 166 -284 265 -455 l64 -113 658 0 658 0 44 78 c50
                87 160 277 285 490 l83 143 -21 42 c-12 23 -36 65 -54 92 -42 67 -107 178
                -138 240 -14 28 -37 67 -51 89 -15 21 -59 95 -97 165 -39 69 -91 159 -116 199
                l-45 72 -517 0 c-389 0 -520 -3 -529 -12 -9 -9 -12 -9 -12 0 0 7 -9 12 -19 12
                -13 0 -34 -24 -64 -72z" />
                                                <path d="M13039 1587 c-9 -12 -51 -85 -94 -162 -43 -77 -93 -162 -110 -190
                -18 -27 -51 -86 -75 -130 -24 -44 -76 -135 -116 -201 -40 -67 -81 -138 -90
                -157 l-16 -34 47 -89 c27 -49 57 -102 68 -117 11 -16 57 -94 102 -175 45 -81
                106 -185 134 -232 28 -47 51 -88 51 -92 0 -5 285 -8 633 -8 l633 0 22 45 c12
                25 47 86 76 135 30 49 82 140 117 202 34 62 71 127 84 143 12 17 41 67 65 112
                l43 81 -85 144 c-47 78 -109 185 -138 237 -28 51 -62 110 -75 130 -13 20 -59
                99 -103 176 -43 77 -87 155 -98 172 l-19 33 -520 0 -520 -1 -16 -22z" />
                                                <path d="M16275 1575 c-11 -19 -49 -86 -84 -148 -35 -61 -90 -155 -121 -207
                -31 -52 -71 -122 -90 -155 -18 -33 -71 -125 -118 -204 l-85 -143 38 -72 c21
                -39 50 -89 65 -111 15 -22 54 -89 88 -150 33 -60 89 -158 124 -217 34 -58 71
                -120 80 -137 l17 -31 631 0 c346 0 630 3 630 8 0 4 23 45 51 92 28 47 89 151
                134 232 45 81 91 159 102 175 11 15 41 68 67 117 56 103 62 77 -58 281 -90
                154 -173 297 -277 480 -37 66 -83 144 -100 173 l-33 52 -520 0 -521 0 -20 -35z" />
                                                <path d="M19147 933 c-25 -43 -64 -110 -86 -150 l-42 -71 91 -153 90 -153 0
                302 c0 166 -2 302 -4 302 -2 0 -24 -35 -49 -77z" />
                                                <path d="M1540 500 c-58 -100 -146 -252 -197 -338 l-91 -157 905 -3 c499 -1
                908 0 911 2 2 3 -23 49 -55 103 -33 54 -77 130 -98 168 -21 39 -66 117 -101
                175 -34 58 -77 132 -96 165 l-34 60 -520 3 -519 2 -105 -180z" />
                                                <path d="M4861 637 c-13 -23 -54 -94 -91 -157 -37 -63 -94 -162 -127 -220 -33
                -58 -80 -140 -106 -182 l-46 -78 914 0 915 0 -47 78 c-25 42 -73 124 -106 182
                -33 58 -90 157 -127 220 -37 63 -78 134 -91 157 l-24 43 -520 0 -520 0 -24
                -43z" />
                                                <path d="M8123 668 c-6 -7 -34 -56 -63 -108 -29 -52 -60 -106 -70 -120 -10
                -14 -44 -72 -75 -130 -32 -58 -84 -148 -117 -201 -32 -54 -58 -100 -58 -103 0
                -3 410 -6 910 -6 l911 0 -28 48 c-27 46 -50 86 -263 452 l-105 180 -516 0
                c-401 0 -519 -3 -526 -12z" />
                                                <path d="M11330 500 c-58 -100 -146 -252 -197 -338 l-91 -157 905 -3 c499 -1
                908 0 911 2 2 3 -23 49 -55 103 -33 54 -77 130 -98 168 -21 39 -66 117 -101
                175 -34 58 -77 132 -96 165 l-34 60 -520 3 -519 2 -105 -180z" />
                                                <path d="M14651 637 c-13 -23 -54 -94 -91 -157 -37 -63 -94 -162 -127 -220
                -33 -58 -80 -140 -106 -182 l-46 -78 914 0 915 0 -47 78 c-25 42 -73 124 -106
                182 -33 58 -90 157 -127 220 -37 63 -78 134 -91 157 l-24 43 -520 0 -520 0
                -24 -43z" />
                                                <path d="M17913 668 c-6 -7 -34 -56 -63 -108 -29 -52 -60 -106 -70 -120 -10
                -14 -44 -72 -75 -130 -32 -58 -84 -148 -117 -201 -32 -54 -58 -100 -58 -103 0
                -3 376 -6 835 -6 l835 0 0 125 c0 147 8 125 -143 382 l-102 173 -516 0 c-402
                0 -519 -3 -526 -12z" />
                                        </g>
                                </svg>
                                <div class="nav-control-sec" fxLayoutAlign="center center">
                                        <div id="navList" class="nav-list" fxLayoutAlign="center center"
                                                fxLayout="column">
                                                <a (click)="mobileNavClick('#home')" class="nav-button">HOME</a>
                                                <a (click)="mobileNavClick('#about')" class="nav-button">ABOUT</a>
                                                <a (click)="mobileNavClick('#experience')" class="nav-button">EXPERIENCE</a>
                                                <a (click)="mobileNavClick('#skills')" class="nav-button">SKILLS</a>
                                                <a (click)="mobileNavClick('#projects')" class="nav-button">PROJECTS</a>
                                                <a (click)="mobileNavClick('#contact')" class="nav-button">CONTACT</a>
                                        </div>
                                </div>
                        </div>
                </div>
        </section>
</section>