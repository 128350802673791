import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Project } from 'src/app/shared/models/project.model';
import { DatabaseService } from 'src/app/shared/services/database.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  jobTitle: string = "Software Engineer";
  alternateTitles: string[] = ["Software Engineer","Mobile Developer","UI Designer", "Team Player", "Coffee Addict"]
  allProjects: Project[] = [];
  shownProjects: Project[] = [];
  lastAddedAmount: number = 0;
  maxAmountOfProjects: number = 0;
  amountOfVisibleProjects: number = 3;
  jobTitleStep: number = 0;
  textArr;
  maxTextIndex;

  sPerChar = 0.05; 
  sBetweenWord = 1.5;
  textIndex = 0; 
  constructor(private databaseService: DatabaseService) {
    
  }

  ngOnInit() {
    this.textArr = document.getElementById("job-title").getAttribute("data-text").split(", ");
    this.maxTextIndex = this.textArr.length; 
    this.typingText(this.textIndex, this.textArr[this.textIndex]); 
    this.initiateList();
  }

  initiateList() {
    this.databaseService.getAllProjects().pipe(take(1)).subscribe(returnedProjects => {
      this.allProjects = returnedProjects.sort(this.compare);
      this.maxAmountOfProjects = this.allProjects.length;
      this.shownProjects = this.allProjects.slice(0, this.amountOfVisibleProjects);
    });
  }

  showMoreProjects() {
    if (this.amountOfVisibleProjects < this.maxAmountOfProjects) {
      this.amountOfVisibleProjects += 3;
      if (this.amountOfVisibleProjects > this.maxAmountOfProjects) {
        this.amountOfVisibleProjects = this.maxAmountOfProjects;
        this.lastAddedAmount = this.amountOfVisibleProjects - this.maxAmountOfProjects;
      }
    }
    this.updateProjectList();
  }

  showLessProjects() {
    if(this.amountOfVisibleProjects == this.maxAmountOfProjects) {
      this.amountOfVisibleProjects = this.maxAmountOfProjects - this.lastAddedAmount;
    }
    this.amountOfVisibleProjects = 3;
    this.updateProjectList();
  }

  updateProjectList() {
    this.shownProjects = this.allProjects.slice(0, this.amountOfVisibleProjects);
  }

  compare( a, b ) {
    if ( a.priority > b.priority ){
      return -1;
    }
    if ( a.priority < b.priority ){
      return 1;
    }
    return 0;
  }

  typingText(textIndex, text) {
    let span = document.getElementById("job-title");
    var charIndex = 0; 
    var maxCharIndex = text.length - 1; 
    
    var typeInterval = setInterval(() => {
        span.innerHTML += text[charIndex]; 
        if (charIndex == maxCharIndex) {
            clearInterval(typeInterval);
            setTimeout(() => { this.deleting(textIndex, text) }, this.sBetweenWord * 1000); 
            
        } else {
            charIndex += 1; 
        }
    }, this.sPerChar * 1000); 
}

deleting(textIndex, text) {
  let span = document.getElementById("job-title");
    var minCharIndex = 0; 
    var charIndex = text.length - 1; 

    var typeInterval = setInterval(() => {
        span.innerHTML = text.substr(0, charIndex); 
        if (charIndex == minCharIndex) {
            clearInterval(typeInterval);
            textIndex + 1 == this.maxTextIndex ? textIndex = 0 : textIndex += 1; 
            setTimeout(() => { this.typingText(textIndex, this.textArr[textIndex]) }, this.sBetweenWord * 100); 
        } else {
            charIndex -= 1; 
        }
    }, this.sPerChar * 1000);
}

visibilityChangeHandler(visibility, element: HTMLElement) {
  if (visibility == "VISIBLE") {
    element.classList.add("is-visible");
  } else {
    element.classList.remove("is-visible");
  }
}

widthChangeHandler(visibility, element: HTMLElement) {
  if (visibility == "VISIBLE") {
    element.classList.remove("is-invisible-width");
  } else {
    element.classList.add("is-invisible-width");
  }
}

leftSkillData = [
  {
    title: "HTML",
    percent: 70
  },
  {
    title: "CSS",
    percent: 70
  },
  {
    title: "SASS",
    percent: 70
  },
  {
    title: "Javascript",
    percent: 67
  },
  {
    title: "Typescript",
    percent: 65
  },
  {
    title: "Java",
    percent: 60
  },
  {
    title: "Kotlin",
    percent: 25
  },
  {
    title: "SQL",
    percent: 50
  },
  {
    title: "Spring Boot",
    percent: 50
  },
  {
    title: "C#",
    percent: 40
  }
];

rightSkillData = [
  {
    title: "Targetprocess",
    percent: 65
  },
  {
    title: "Xamarin",
    percent: 45
  },
  {
    title: "PHP",
    percent: 20
  },
  {
    title: "Python",
    percent: 20
  },
  {
    title: "Angular",
    percent: 70
  },
  {
    title: "React",
    percent: 60
  },
  {
    title: "Vue.Js",
    percent: 35
  },
  {
    title: "Git",
    percent: 70
  },
  {
    title: "Gitlab",
    percent: 60
  },
  {
    title: "Jira",
    percent: 55
  }
];

    delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
}
