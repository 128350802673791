import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Project } from 'src/app/shared/models/project.model';
import { DatabaseService } from 'src/app/shared/services/database.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  allProjects: Project[] = [];
  allHexagons: NodeListOf<SVGPathElement>;

  constructor(private databaseService: DatabaseService, private router: Router) { }

  ngOnInit() {
    this.initiateList();
    this.getAllHexagons();
  }

  initiateList() {
    this.databaseService.getAllProjects().pipe(take(1)).subscribe(returnedProjects => {
      this.allProjects = returnedProjects.sort(this.compare);
    });
  }

  visibilityChangeHandler(visibility, element: HTMLElement) {
    if (visibility == "VISIBLE") {
      element.classList.add("is-visible");
    } else {
      element.classList.remove("is-visible");
    }
  }

  textVisibilityChangeHandler(visibility, element: HTMLElement) {
    if (visibility == "VISIBLE") {
      element.classList.add("text-is-visible");
    } else {
      element.classList.remove("text-is-visible");
    }
  }

  compare( a, b ) {
    if ( a.completion > b.completion ){
      return -1;
    }
    if ( a.completion < b.completion ){
      return 1;
    }
    return 0;
  }
  

  getAllHexagons() {
    this.allHexagons = document.querySelectorAll("path");
    this.allHexagons.forEach(hexagon => {
      hexagon.addEventListener("mousover", function (d) {
        this.parentNode.appendChild(hexagon);
      });
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async projectClick(id: string) {
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.add("active-hexagon");
    };
    this.router.navigateByUrl(`projects/${id}`);
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.remove("active-hexagon");
    }
  }
}
