import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { HomeComponent } from './components/home/home.component';
import { NavDashboardComponent } from './components/navDashboard/navDashboard.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProjectDetailComponent } from './components/projects/project-detail/project-detail.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { SkillsComponent } from './components/skills/skills.component';


const routes: Routes = [
  {
    path: "",
    component: NavigationComponent,
    children: [
      { path: "", pathMatch: "full", component: HomeComponent },
      { path: "skills", pathMatch: "full", component: SkillsComponent },
      { path: "about", pathMatch: "full", component: AboutComponent },
      { path: "projects", pathMatch: "full", component: ProjectsComponent },
      { path: "projects/:id", pathMatch: "full", component: ProjectDetailComponent },
    ],
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
