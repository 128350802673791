<section class="main-sub-sec">
  <div class="body-sub-sec">
    <div class="list-container" fxLayout="column">
      <div *ngFor="let project of allProjects; let i = index" class="project-section" fxLayout="row">
        <div fxLayoutAlign="center center" class="split-sec l">
          <div fxLayoutAlign="center center" fxLayout="column" #titleContainer enterTheViewportNotifier (visibilityChange)="textVisibilityChangeHandler($event, titleContainer)" class="title-container">
            <h2 class="index-title">{{i + 1}}</h2>
            <h1 class="project-title">{{project.title}}</h1>
            <button (click)="projectClick(project)">More info</button>
          </div>
        </div>
        <div class="split-sec r" fxLayoutAlign="center center">
          <div #video enterTheViewportNotifier (visibilityChange)="visibilityChangeHandler($event, video)" class="shadow-wrapper">
            <!--<div class="label-container" fxLayoutAlign="center center">
              <label class="completion-label done" *ngIf="project.completion == 100">DONE</label>
              <label class="completion-label" *ngIf="project.completion != 100">{{project.completion}}%</label>
            </div>
            <div class="img-display-container" fxLayoutAlign="center center">
              <div class="image-display">
                <video loop="true" autoplay="autoplay" disableRemotePlayback muted="muted" oncanplay="this.muted=true; play()">
                  <source [src]="project.image" type="video/mp4">
                </video>
              </div>
            </div>-->
            <img src="{{project.gif}}">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="show-on-scroll">HELLO</div>
</section>