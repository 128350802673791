import { Project } from '../models/project.model';
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { AngularFirestore} from '@angular/fire/firestore';

@Injectable()
export class DatabaseService {

    constructor(private firestore: AngularFirestore) {
    }

        //Child management
        getAllProjects() {
            return this.firestore.collection("projects").snapshotChanges().pipe(map(changes => {
                return changes.map(a => {
                    const data = a.payload.doc.data() as Project
                    data.id = a.payload.doc.id;
                    return data;
                })
            }));
        }
    
        getProjectWithId(id: string) {
            return this.firestore.collection("projects").doc(id).get();
        }
    
        updateProject(project: Project) {
            let projectsDoc = this.firestore.doc("/projects" + `/${project.id}`);
            return projectsDoc.update(project);
        }
    
        deleteProject(project: Project) {
            let projectDoc = this.firestore.doc("/projects" + `/${project.id}`);
            return projectDoc.delete();
        }
    
        async createProject(project: Project) {
            const doc_ref = await this.firestore.collection("projects").add(project);
            project.id = doc_ref.id;
            return project;
        }
}