import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HomeComponent } from './components/home/home.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { ProjectsComponent } from './components/projects/projects.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from 'src/environments/environment';
import { DatabaseService } from './shared/services/database.service';
import { EnterTheViewportNotifierDirective } from './shared/directives/enter-the-view-port.directive';
import { ProjectDetailComponent } from './components/projects/project-detail/project-detail.component';
import { AboutComponent } from './components/about/about.component';
import { AudioService } from './shared/services/audio.service';
import { SkillsComponent } from './components/skills/skills.component';
import { NavDashboardComponent } from './components/navDashboard/navDashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    AboutComponent,
    EnterTheViewportNotifierDirective,
    SkillsComponent,
    NavDashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FlexModule,

        //Firebase
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule
  ],
  providers: [DatabaseService, AudioService],
  bootstrap: [AppComponent]
})
export class AppModule { }
