import { Injectable } from '@angular/core';
import {Howl, Howler} from 'howler';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

constructor() { }
keyboard = new Howl({
  src: ["../../assets/audio/keyboard.wav"]
});
theme = new Howl({
  src: ["../../assets/audio/theme.wav"]
});
expand = new Howl({
  src: ["../../assets/audio/expand.wav"]
});
panels = new Howl({
  src: ["../../assets/audio/panels.wav"]
});
scan = new Howl({
  src: ["../../assets/audio/scan.wav"]
});
denied = new Howl({
  src: ["../../assets/audio/denied.wav"]
});
granted = new Howl({
  src: ["../../assets/audio/granted.wav"]
});
info = new Howl({
  src: ["../../assets/audio/info.wav"]
});
alarm = new Howl({
  src: ["../../assets/audio/alarm.wav"]
});
error = new Howl({
  src: ["../../assets/audio/error.wav"]
});
stdout = new Howl({
  src: ["../../assets/audio/stdout.wav"],
  volume: 0.4
});
stdin = new Howl({
  src: ["../../assets/audio/stdin.wav"],
  volume: 0.4
});

playStdOut() {
  this.stdout.play();
}

playGranted() {
  this.granted.play();
}

playTheme() {
  this.theme.play();
}
}


