import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navDashboard',
  templateUrl: './navDashboard.component.html',
  styleUrls: ['./navDashboard.component.scss']
})
export class NavDashboardComponent implements OnInit {
  allHexagons: NodeListOf<SVGPathElement>;
  constructor(private router: Router) { }

  ngOnInit() {
    this.getAllHexagons();
  }

  getAllHexagons() {
    this.allHexagons = document.querySelectorAll("path");
    this.allHexagons.forEach(hexagon => {
      hexagon.addEventListener("mousover", function (d) {
        this.parentNode.appendChild(hexagon);
      });
    });
  }

  async navClick(route: string) {
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.add("active-hexagon");
    };
    this.router.navigateByUrl(route);
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.remove("active-hexagon");
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
