import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.populateData();
  }

  chartData = [
    {
      title: "HTML",
      percent: 70
    },
    {
      title: "CSS",
      percent: 70
    },
    {
      title: "Javascript",
      percent: 67
    },
    {
      title: "Typescript",
      percent: 65
    },
    {
      title: "Java",
      percent: 60
    },
    {
      title: "Kotlin",
      percent: 25
    },
    {
      title: "Spring Boot",
      percent: 50
    },
    {
      title: "C#",
      percent: 40
    },
    {
      title: "Python",
      percent: 20
    },
    {
      title: "Git",
      percent: 70
    },
    {
      title: "Gitlab",
      percent: 60
    },
  ];

  async populateData() {
    for (let i = 0; i < this.chartData.length; i++) {

      let chart = document.getElementById("graph");
      let progressBar = document.createElement("div");
      let barLabel = document.createElement("label");
      let progressTrack = document.createElement("div");
      let progressFill = document.createElement("div");

      progressBar.setAttribute("id", this.chartData[i].title);
      progressBar.setAttribute("class", "progressBar");
      barLabel.setAttribute("class", "barLabel");
      progressTrack.setAttribute("class", "progressTrack");
      progressFill.setAttribute("class", "progressFill");

      chart.appendChild(progressBar);
      progressBar.appendChild(barLabel);
      progressBar.appendChild(progressTrack);
      progressTrack.appendChild(progressFill);
      barLabel.appendChild(document.createTextNode(this.chartData[i].title));

      await this.delay(200);
      progressFill.style.width = this.chartData[i].percent + "%" };

    }
    delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
