import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AudioService } from 'src/app/shared/services/audio.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  menu: HTMLElement;
  menuActive: boolean = false;
  showUi: boolean = false;
  allHexagons: NodeListOf<SVGPathElement>;
  navScreen: HTMLElement;
  navList: HTMLElement;
  svg: HTMLElement;

  constructor(private router: Router, private audioService: AudioService) { }

  ngOnInit() {
    window.addEventListener('scroll', this.onWindowScroll, true)
  }

@HostListener('window:scroll', ['$event'])
onWindowScroll() {
    let mainSection = document.getElementById('home') as HTMLElement;
    let navbar = document.getElementById('navBar') as HTMLElement;
    if (mainSection.scrollTop > 0) {
      navbar.classList.add('nav-bar-scrolled');
    } else {
      navbar.classList.remove('nav-bar-scrolled');
    }
  }

  fetchUiComponents() {
    this.menu = document.getElementById("nav-menu");
    this.navScreen = document.getElementById("navScreen");
    this.navList = document.getElementById("navList");
    this.svg = document.getElementById("hexagons");
    this.getAllHexagons();
  }

  initiateBoot() {
    document.getElementById("boot-btn").remove();
    this.displayLine();
  }

  async toggleMenu() {
    this.menuActive = !this.menuActive;
    if (this.menuActive) {
      for (let i = 0; i < this.allHexagons.length; i++) {
        await this.delay(10);
        this.allHexagons[i].classList.add("active-hexagon");
      }
      this.navList.classList.add("nav-list-active");
      this.navScreen.classList.add("active-nav-screen");
    } else {
      this.navList.classList.remove("nav-list-active");
      this.navScreen.classList.remove("active-nav-screen");
      for (let i = 0; i < this.allHexagons.length; i++) {
        await this.delay(10);
        this.allHexagons[i].classList.remove("active-hexagon");
      }
    }
  }

  getAllHexagons() {
    this.allHexagons = document.querySelectorAll("path");
    this.allHexagons.forEach(hexagon => {
      hexagon.addEventListener("mousover", function (d) {
        this.parentNode.appendChild(hexagon);
      });
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async navClick(route: string) {
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.add("active-hexagon");
    };
    this.router.navigateByUrl(route);
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.remove("active-hexagon");
    }
  }

  async scrollToSection(elementId: string) {
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.add("active-hexagon");
    };
    window.location.href = elementId;
    for (let i = 0; i < this.allHexagons.length; i++) {
      await this.delay(10);
      this.allHexagons[i].classList.remove("active-hexagon");
    }
  }

  async mobileNavClick(elementId: string) {
    window.location.href = elementId;
    this.toggleMenu();
  }


  // Startup boot log
 async displayLine() {
  let bootScreen = document.getElementById("boot_screen");
  let cli = document.createElement("div");
  cli.setAttribute("id", "cli");
  cli.setAttribute("class", "cli-output");
  bootScreen.appendChild(cli);

  fetch("../../assets/data/boot_log.txt").then(response => response.text())
  .then(data => {
    return data.toString().split('\n');
  }).then(async log => {
    for(let i = 0; i < log.length; i++) {
      if (i === 85) {
        this.audioService.playGranted();
    } else {
        this.audioService.playStdOut();
    }
    cli.innerHTML += log[i]+"<br/>";
  
    switch(true) {
        case i === 2:
            //bootScreen.innerHTML += `eDEX-UI Kernel version ${version} boot at ${Date().toString()}; root:xnu-1699.22.73~1/RELEASE_X86_64`;
        case i === 4:
            await this.delay(500);
            break;
        case i > 4 && i < 25:
          await this.delay(30);
            break;
        case i === 25:
          await this.delay(400);
            break;
        case i === 42:
          await this.delay(300);
            break;
        case i > 42 && i < 82:
          await this.delay(25);
            break;
        case i === 83:
            break;
        case i >= log.length-2 && i < log.length:
          await this.delay(300);
            break;
        default:
          await this.delay(Math.pow(1 - (i/1000), 3)*25);
    }
    }
    this.initBoot();
  });
}

  async initBoot() {
    let bootScreen = document.getElementById("boot_screen");
    if (bootScreen === null) {
        bootScreen = document.createElement("section");
        bootScreen.setAttribute("id", "boot_screen");
        bootScreen.setAttribute("class", "boot-bg")
        bootScreen.setAttribute("style", "z-index: 10");
        document.body.appendChild(bootScreen);
    }
    bootScreen.innerHTML = "";
    this.audioService.playTheme();
    

    await this.delay(400);

    document.body.setAttribute("class", "");
    bootScreen.classList.add("boot-bg");
    bootScreen.innerHTML = "<h1>WAEBEN-UI</h1>";
    let title = document.querySelector("h1");
    title.classList.add('boot-logo');

    await this.delay(200);

    document.body.setAttribute("class", "solidBackground");

    await this.delay(100);

    title.setAttribute("style", `background-color: #A7CECD;border-bottom: 5px solid #A7CECD;`);

    await this.delay(300);

    title.setAttribute("style", `border: 5px solid #A7CECD;`);

    await this.delay(100);

    title.setAttribute("style", "");
    title.classList.add("glitch");

    await this.delay(500);

    document.body.setAttribute("class", "");
    title.classList.remove("glitch");
    title.setAttribute("style", `border: 5px solid #A7CECD;`); 
    await this.delay(1000);
    this.displayWelcome()
  }

  async displayWelcome() {
    let bootScreen = document.getElementById("boot_screen");
    bootScreen.innerHTML = "";

    await this.delay(400);

    document.body.setAttribute("class", "");
    bootScreen.classList.add("boot-bg");
    bootScreen.innerHTML = "<h2>Welcome back, <span>Administrator</span></h2>";
    let title = document.querySelector("h2");
    let name = document.querySelector("span");
    title.classList.add("welcome-msg");
    name.classList.add("username");
    await this.delay(200);
    title.classList.add("opacity-anim");
    await this.delay(100);
    name.classList.add("opacity-anim");
    await this.delay(2500);
    title.remove();
    name.remove();
    await this.delay(1000);
    bootScreen.remove();
    this.showUi = true;
    await this.delay(200);
    this.fetchUiComponents();
  }
}
